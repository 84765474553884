<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ATTACHMENT_TYPOLOGY_TYPES } from './store'
import { ROUTES as ATTACHMENT_TYPOLOGY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import AttachmentTypologyTable from './AttachmentTypologyTable'
import AttachmentTypologyCommonFilters from './AttachmentTypologyCommonFilters'
import AttachmentTypologyEdit from './AttachmentTypologyEdit.vue'

export default {
  name: 'AttachmentTypologyList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': AttachmentTypologyTable,
    'common-filters': AttachmentTypologyCommonFilters,
    'component-detail': AttachmentTypologyEdit,
    'component-edit': AttachmentTypologyEdit
  },
  data () {
    return {
      ATTACHMENT_TYPOLOGY_ROUTES,
      title: this.$t('Attachment typology'),
      rolePerm: ['miscellaneous_attachmenttypology_list'],
      actionEnablePermission: ['miscellaneous_attachmenttypology_enable'],
      actionDisablePermission: ['miscellaneous_attachmenttypology_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...ATTACHMENT_TYPOLOGY_TYPES.GENERIC.miscellaneous.attachmenttypology.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ATTACHMENT_TYPOLOGY_ROUTES.ATTACHMENT_TYPOLOGY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_attachmenttypology_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ATTACHMENT_TYPOLOGY_TYPES.GENERIC.miscellaneous.attachmenttypology.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ATTACHMENT_TYPOLOGY_TYPES.GENERIC.miscellaneous.attachmenttypology.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ATTACHMENT_TYPOLOGY_TYPES.GENERIC.miscellaneous.attachmenttypology.LIST.ACTIONS
    })
  }
}
</script>
